import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import OrderDetails from './OrderDetails';
import OrderPayment from './OrderPayment';
import OrderSchedule from './OrderSchedule';
import OrderReport from './OrderReport';
import OrderReview from './OrderReview';
import OrderAppraiser from './OrderAppraiser';
import OrderDeliver from './OrderDeliver';
import OrderDeskReview from './OrderDeskReview';
import CustomLoader from './CustomLoader';
import LoginState from './LoginState';
import OrderBidders from './OrderBidders';

export default function Order(props) {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [orderFiles, setOrderFiles] = useState(null);
  const [error, setError] = useState(false);

  const _getOrderDetails = async () => {
    let response = await axios.get('/getordersummary', {
      params: { pOrderID: id },
    });
    if (response.data) {
      setOrder(response.data);
    } else {
      setError(true);
    }
  };

  const _getOrderFiles = async () => {
    let response = await axios.get('/getorderpurchasefiles', {
      params: { pOrderID: id },
    });
    if (response.data) {
      setOrderFiles(response.data);
    } else {
      setError(true);
    }
  };

  // const _shouldShow = status => {
  //     if(LoginState.userRole === 'admin'){
  //         statusbar
  //     } else if(LoginState.userRole === 'appraiser'){

  //     } else {

  //     }
  // }

  useEffect(() => {
    if (!order && !error) {
      _getOrderDetails();
    }
    if (!orderFiles && !error) {
      _getOrderFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderFiles, order, error]);

  if (!order) {
    return <CustomLoader />;
  }

    return (
        <Container mb={2}>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={4}>
                    <OrderDetails order={order} orderFiles={orderFiles} afterCompleted={_getOrderDetails} />
                </Grid>
                <Grid item xs={12} md={8} sx={{ maxHeight: { xs: 'auto', lg: '90vh' }, pr: { xs: 0, lg: 1 }, pb: 4 }}>
                    {LoginState.userRole === 'Admin' && (<>
                      {order.status === "Submitted" ? 
                        <OrderBidders order={order} afterCompleted={_getOrderDetails} /> :
                        <OrderAppraiser order={order} afterCompleted={_getOrderDetails} deskReviewNotReady={order.orderType === "Desk Review" && !order.deskReviewFile} />
                      }
                    </>)}
                    {/* {LoginState.userRole === 'Appraiser' && <UploadInvoice order={order} afterCompleted={_getOrderDetails} />} */}
                    {order.orderType === "Desk Review" && <OrderDeskReview order={order} afterCompleted={_getOrderDetails} />}
                    {LoginState.userRole === 'Admin' && <OrderPayment order={order} afterCompleted={_getOrderDetails} />}
                    {(order.status !== 'Ordered' && order.status !== 'Submitted' && order.orderType !== "Desk Review") && <OrderSchedule order={order} afterCompleted={_getOrderDetails} />}
                    {/* Normally, we don't display the order review page for appraisers (where they submit the report) until after the report has been scheduled (where order status is 
                    past the 'ordered' and 'assigned' stage). However, for a 'desk review', there's nothing to schedule, so after the order has been assigned, they can immediately
                    go to the 'review' stage. */}
                    {LoginState.userRole === 'Appraiser' && (order.status !== 'Ordered' && (order.orderType === "Desk Review" || order.status !== 'Assigned')) && <OrderReport order={order} />}
                    {(order.status === 'Scheduled' || order.status === 'AMC Review' || order.status === 'UW Review' || order.status === 'Reviewed' ||
                      order.status === 'Completed') && LoginState.userRole === 'Admin' &&
                      <OrderReview order={order} afterCompleted={_getOrderDetails} />}
                    {((order.status === 'Reviewed') && LoginState.userRole === 'Admin') && <OrderDeliver order={order} afterCompleted={_getOrderDetails} />}
                </Grid>
            </Grid>
        </Container>
    )
}
