import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineConnector,
  TimelineDot,
  TimelineSeparator,
  TimelineContent,
} from '@mui/lab';
import React from 'react';
import {
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Stack,
} from '@mui/material';
import LoginState from './LoginState';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CommentIcon from '@mui/icons-material/Comment';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
import VerifiedIcon from '@mui/icons-material/Verified';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { green, blue, red } from '@mui/material/colors';

const FileItem = ({ orderID, file, download, remove }) => (
  <TimelineItem>
    <TimelineOppositeContent
      // style={{ paddingLeft: '0px', paddingRight: '0px' }}
      sx={{ m: 'auto 0', flex: 0 }}
      align="right"
      variant="body2"
      color="text.secondary"
    >
      {file.timestamp.split(' ')[0]}
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot>
        <InsertDriveFileIcon />
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'grey.100',
            p: 1,
            pl: 2,
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1">{file.name}</Typography>
          <IconButton sx={{ m: 0 }}>
            <DownloadIcon onClick={() => download(orderID, file.name)} />
          </IconButton>
        </Box>
        {LoginState.userRole === 'Admin' && (
          <IconButton sx={{ ml: 1 }}>
            <DeleteIcon onClick={() => remove(orderID, file.name)} />
          </IconButton>
        )}
      </Box>
      <Typography variant="caption">{file.user}</Typography>
    </TimelineContent>
  </TimelineItem>
);

const CommentItem = ({ comment }) => (
  <TimelineItem>
    <TimelineOppositeContent
      // style={{ paddingLeft: '0px', paddingRight: '0px' }}
      sx={{ m: 'auto 0', flex: 0 }}
      align="right"
      variant="body2"
      color="text.secondary"
    >
      {comment.timestamp.split(' ')[0]}
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot>
        <CommentIcon />
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent>
      <Box sx={{ ml: 4, backgroundColor: 'grey.100', p: 2, borderRadius: 2 }}>
        <Typography variant="body1">{comment.commentText || '-'}</Typography>
        <Typography color="grey.700" variant="caption">
          {comment.userName}
        </Typography>
      </Box>
    </TimelineContent>
  </TimelineItem>
);
const ApprovalItem = ({ approval }) => {
  const lenderApproval = approval.commentText.includes('Lender]');
  return (
    <TimelineItem>
      <TimelineOppositeContent
        // style={{ paddingLeft: '0px', paddingRight: '0px' }}
        sx={{ m: 'auto 0', flex: 0 }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        {approval.timestamp.split(' ')[0]}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot
          sx={{ backgroundColor: lenderApproval ? green['600'] : blue['400'] }}
        >
          {lenderApproval ? <VerifiedIcon /> : <CheckIcon />}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Box
          sx={{
            backgroundColor: lenderApproval ? green[50] : blue[50],
            p: 2,
            borderRadius: 2,
          }}
        >
          <Typography variant="body1">{approval.commentText || '-'}</Typography>
          <Typography
            color={lenderApproval ? 'grey.900' : 'grey.700'}
            variant="caption"
          >
            {approval.userName}
          </Typography>
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

// This Rejection item is to show change request items
const RejectionItem = ({ rejection }) => (
  <TimelineItem>
    <TimelineOppositeContent
      // style={{ paddingLeft: '0px', paddingRight: '0px' }}
      sx={{ m: 'auto 0', flex: 0 }}
      align="right"
      variant="body2"
      color="text.secondary"
    >
      {rejection.timestamp.split(' ')[0]}
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot sx={{ backgroundColor: red['600'] }}>
        <CloseIcon />
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent>
      <Box
        sx={{
          backgroundColor: red[50],
          p: 2,
          borderRadius: 2,
        }}
      >
        <Typography variant="body1">{rejection.commentText || '-'}</Typography>
        <Typography color="grey.700" variant="caption">
          {rejection.userName}
        </Typography>
      </Box>
    </TimelineContent>
  </TimelineItem>
);

export default function ReportList({ orderID, items, download, remove }) {
  if (!items) {
    return (
      <Stack justifyContent="center" alignItems="center" my={4}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Timeline position="right" sx={{ px: 0 }}>
      {/* TODO: we need to add a type to items such as reject, comment, approval, that way we can clearly differentiate the items in the timeline */}
      {items.map((item) => {
        if (item.name) {
          return (
            <FileItem orderID={orderID} key={item.timestamp} file={item} download={download} remove={remove} />
          );
        } else {
          if (item.commentText.includes('[Approved') || item.commentText.includes('Review]')) {
            return <ApprovalItem key={item.timestamp} approval={item} />;
          } else {
            return <CommentItem key={item.timestamp} comment={item} />;
          }
        }
      })}
    </Timeline>
  );
}
